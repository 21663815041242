.brand.hentry {
    min-height: 150px;
    width: 25%;
    padding: 20px 20px;
    float: left;
    display: block;
    vertical-align: middle;
}
.brand.hentry:nth-child(4n+1) {
    clear: left;
}

.img-lifestyle-logo {
    display: inline-block;
    position: absolute;
    max-height: 60px;
    width: auto;
    margin-top: -25px;
    margin-left: 10px;
}

.lifestile-item {    
    @include make-sm-column(2);
    @include make-xs-column(4);
}

@media(max-width: $screen-md-max) { 
    .brand.hentry {
        width: 25%;
    }
    .brand.hentry:nth-child(4n+1) {
        clear: left;
    }
}
@media(max-width: $screen-sm-max) { 
    .brand.hentry {
        width: 33%;
    }
    .brand.hentry:nth-child(4n+1) {
        clear: none;
    }
    .brand.hentry:nth-child(3n+1) {
        clear: left;
    }
}
@media(max-width: $screen-xs-max) { 
    .brand.hentry {
        width: 100%;
        .img-responsive {
            margin: 0 auto;
        }
        clear: both;
    }
    
    .block-events table {
        display: block;
        
        thead {
            display: none;
        }
        tr {
            display: block;
            margin: 10px 0 10px ;           
            padding: 10px 0;
            border-bottom: 1px solid #ddd;
        }
        td {
            display: block;
            border: none !important;
            padding: 5px 0!important;
        }
        td:first-child {
            font-size: 150%;            
        }
        td:nth-child(2), td:nth-child(3) {
            opacity: 0.8;
            padding: 0!important;
        }
    }
}