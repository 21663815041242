﻿
.case-studies-search {
    text-align:center;
    margin: 50px 0 20px;
}
.case-study-content {
    font-size: $font-size-base * 18/19;    
}
.case-study-block {
    border-bottom: 1px solid rgb(0,200,255);
    margin: 20px 0;
        
    h4 {
        margin: 10px 0 20px;
        color: $brand-primary;
        
        .case-study-title {
            background: $brand-primary;
            color: #fff;
            padding: 3px 10px;
            display: inline-block;
            min-width: 10em;
            font-weight: bold;
            float:left;
        }
    }
    .tags {
        margin: 0;
        padding: 0;
        overflow: hidden;
        li {
            /*display: inline-block;*/
            float:left;
            margin: 0 10px 0 10px;
            padding: 2px 10px 2px 0px;
            line-height: 1.2;
        }
    }
}
.case-study-block {
    display: none;
}
.frm-case-studies {
    .form-group {
        padding: 0;
        margin: 10px 0%;
        
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }        
    }    
    .cs-dropdown-label,
    label {
        width: 100%;
        display: block;
        background: $brand-primary;
        color: #fff;
        font-size: $font-size-base * 17/19;  
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        padding: 2px;
        margin: 0;
        border: none;
    }
    .cs-selected-value {
        height: 24px;        
    }
    .cs-dropdown-menu {
        top: auto;
    }
    
    .cs-selected-value,
    .form-input {
        margin: 0;
        width: 100%;
        display: block;
        padding: 5px 10px!important;        
        color: rgb(0,200,255);
        font-size: $font-size-base * 14/19;  
        text-transform: uppercase;
        
        outline: none;
    }
    .cs-selected-value.cs-dropdown-has-value {
        background: rgb(77,77,77);        
    }
}
.cs-dropdown-menu {
    border-radius: 0;
    background: #ccf4ff;
    border-color: #ccf4ff;
    a {
        color: #222;
    }    
}
.cs-dropdown-submenu >li>a:focus, .dropdown-submenu>li>a:hover,
.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
    color: $brand-primary;
    background: transparent;
    text-decoration: none;
}

.cs-dropdown-submenu {
    list-style: none;
}

@media(min-width: $screen-xs-max) { 
.frm-case-studies {
    .form-group {
        width: 18%;
        margin: 0 0.25%;
    }
}
}