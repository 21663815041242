// Grid system
.container {
		margin: 0 auto 0 0;
}
.main {
  margin-left: $left-column-width + 15px;   
}
.sidebar {
    width: $left-column-width;  
    float: left;
}
.admin-bar .sidebar {
    top: 92px;
}

@media(max-width: $screen-sm-max){
    .container {
        width: 100%;
    }
}
@media(max-width: $screen-ipad-max){
    .main {
        margin-left: 0;  
    }
}