header.banner {

    background: rgb(212,209,208);
    height: $navbar-height;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    color: rgb(0,0,0);
    z-index: 100;
    
    
    .brand {
        float: left;
        width: $left-column-width;
        text-align: center;
    }
    .logo {
        display: inline-block;
        max-height: 50px;
        margin-top: 20px;
    }
    #primary-nav {
				padding-left: 0;
     		padding-right: 0;
     		margin-left: $left-column-width;
    }
    .nav-primary {
        background: #fff;
        display: table;
        width: 100%;
        padding-left: 40px;
        .navbar-nav {
            float:none;
        }       
    }
    .navbar-top-header {
    		background: rgb(178,178,180);
    		margin-left: $left-column-width;
    		height: $footer-height;
    		text-align: right;
    		.btn {
    		    border-radius: 0;
				line-height: $footer-height;
				box-shadow: none; 
				padding: 0 80px;
				border: none;
				font-size: $font-size-base*22/14;    
                font-family: "BigNoodleTitling Regular", "BigNoodleTitling", "bignoodletitlingregular", "Helvetica Neue", Helvetica, Arial, sans-serif;
    
    		}
    }
    .navbar-header {
        float: left;
    }
    
}

.admin-bar {
    header.banner {
        top: 32px;
    }
}

// navbar
.navbar-default {
    border:none !important;
    text-transform: uppercase;
    font-size: $font-size-base*22/14;    
    font-family: "BigNoodleTitling Regular", "BigNoodleTitling", "bignoodletitlingregular", "Helvetica Neue", Helvetica, Arial, sans-serif;
    .caret {
        display: none;
    }
    
    .dropdown.toggled,        
    .dropdown:hover {
        .dropdown-menu {
            display: block;
        }
        .caret {
            display: block;
            width: 7px;
            position: absolute;
            border-top: 2px solid $brand-primary;
            border-left: 2px solid $brand-primary;
            left: -2px;
            top: 25px;
            height: 25px;
        }
    
    }
    .dropdown-menu {
        border-radius: 0;
        background: $brand-primary;
        a {
            color: #000;
            &:hover {
                color: #fff;
            }            
            background: transparent;  
            font-size: $font-size-base*18/14;              
        }
    }
    .dropdown-menu>li>a,
    .dropdown-menu>li>a:hover,
    .dropdown-menu>li>a:focus,
    .dropdown-menu>.active>a, 
    .dropdown-menu>.active>a:focus, 
    .dropdown-menu>.active>a:hover {
        background: transparent;            
    }
    .dropdown-menu>.current_page_item>a {
    		color: #fff;
    } 
    .dropdown-menu>.active>a:focus, 
    .dropdown-menu>.active>a:hover {
        color: #fff;
    }
}
.navbar-toggle {    
    border-color: $brand-primary;
    border:none;
    background: transparent !important;
    margin-top: 20px;
    margin-bottom: 5px;
    margin-left: 20px;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: $brand-primary;
}


@media (min-width: $grid-float-breakpoint) {
    .navbar-default .navbar-right .sub-menu, .navbar-right .dropdown-menu, .navbar-right .navbar-default .sub-menu {
        right: auto;
        left: 0;
    }
    .navbar-nav {
    		display: table-row;
    		>li {
				display: table-cell;
				text-align:left;
				float: none;
    				
    		}
    }
    .navbar-nav>li>a {
        padding: 15px 20px !important;
        line-height: 20px;       
    }
}



@media(max-width: $screen-md-max) {   
    header.banner {
        .nav-primary {
            padding-left: 10px;               
        }
    }
}

@media(max-width: $grid-float-breakpoint) {     
    .navbar-toggle {
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 0px;
    }    
    header.banner {
        .navbar-top-header {
    		background: transparent;
    		margin-left: $left-column-width;
    		text-align: right;
    		.btn {    		    								
				padding: 0 40px;
				font-size: $font-size-base*22/14;                        
    		}
    	}
    	#primary-nav {
    	    width: 100%;    
    	    margin: 0 0px!important;
    	    
    	    overflow: visible;
    	    &.in {
    	        display: block;
    	    }
    	    .navbar-nav {
    	        margin: 0 0px!important;
    	    
    	        background: transparent;
    	        display: block;
    	        > li {
    	            display: block;
    	            > a {
    	                display: block;    	    
    	            }
    	        }
    	    }
    	}
    }
}    
@media(max-width: $screen-sm-max) { 
    .navbar-nav > li> a {
        padding: 15px 10px !important;
        font-size: $font-size-base*20/14;    
    }
}

@media(max-width: ($screen-sm-max) - 100px) { 
    .navbar-nav > li> a {
        padding: 15px 8px !important;
        font-size: $font-size-base*19/14;    
    }
    .navbar-default > .container {
        padding-right: 0;
    }
}



@media(max-width: $screen-xs-max) { 
    header.banner {
        .logo {
            display: inline-block;
            max-width: 150px;
            margin-top: 20px;
        }
        .brand {
            width: auto;
            padding-left: 20px;  
            padding-top: 10px;  
        }
        .navbar-top-header {
            margin-left: 150px!important;
            .btn {
    		    line-height: $footer-height;
				padding: 0 20px;
				font-size: $font-size-base*20/14;                    
    		}
        }
    }
    
}