﻿
.block-contentimage {
    margin: 20px 20px;
    
    .block-contentimage-text {
        width: 50%;
        float: left;
        padding: 0 20px;
    }
    .block-contentimage-image {
        width: 50%;
        float: left;
        padding: 0 20px;
    }
}

.block-contentimage-right {
    .block-contentimage-image {
        float: right!important;
    }
}

@media(max-width: $screen-sm-max) { 
    .block-contentimage-text,
    .block-contentimage-image,
    .block-contentimage-right .block-contentimage-image {
        float: none!important;
        width: 100%!important;
        margin: 40px 0;
    }
}