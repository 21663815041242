// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}


//forms 
.gform_wrapper {
    margin: 0 !important;
    width: 66%;
    ul {
        margin: 0;
        padding: 0;
        li {
            margin: 0;
            padding: 0;
            text-indent: 0;
        }
    }
    
    li.gfield.gfield_error,
    li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
        background: transparent!important;
        border-top: 0;
        border-bottom: 0;
        
        margin-bottom: .375em !important;
        padding: 0;
        input, textarea {
            background: #FFDFE0!important;
        }
    }
}
.gfield {
    position: relative;
}
input[type=text],
input[type=url],
input[type=email],
input[type=tel],
input[type=number],
input[type=password], 
select, 
textarea {
    background: #e4e3e3;
    border: 1px solid #e4e3e3;
    border-radius: 10px;
    padding: 10px 20px!important;
    color: #404042!important;
}
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #404042!important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: #404042!important;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: #404042!important;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #404042!important;
}
:placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  color: #404042!important;
}

.gfield_label {
    display: none;
}

.gform_wrapper .gform_footer {
    padding: 0 2.5% 0 0 !important;
    text-align: left;
    
}
.gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit] {
    background: #e4e3e3;
    border: 1px solid #e4e3e3;
    color: #404042;
    text-transform: uppercase;
    padding: 10px 25px;
    border-radius: 10px;
    &:hover {
        color: #fff;
        background: $brand-primary;
    }
}
