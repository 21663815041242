﻿.news-container {
/*    background: #edebec;*/
    padding: 15px 0 15px 0;
    
    h3 {
        margin: 30px 0;
        .btn {
            margin-left: 20px;
            line-height: 1;
        }
    }    
    a {
        color: $brand-primary;
    }
    .btn-primary {
        color: #fff;
    }
    .read-more.btn {
        clear: both;
    }
    .popover {
        max-width: 600px;
    }
    .arrow {
        display: none;
    }
    .popover-content {
        width: 500px;
        
        img {
            display: block;
            margin: 20px 0 40px;
        }
    }
}
    .attachment-news {
        max-width: 100%;
        height: auto;
    }

.featured-news {
    margin-top: 20px;
    margin-bottom: 50px;                
    
    .read-more {
        @extend .btn; 
        @extend .btn-primary;
        @extend .btn-sm;
        max-width: 4em;
        white-space: normal;
    }
}
.news-post {
    padding-bottom: 40px;
    position: relative;
    .read-more {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}
.news-list {
    padding: 0;
    margin: 20px 0;
    list-style: none;
    li {
        padding: 10px 0;
        margin: 0px 0 0px;
        border-bottom: 1px solid #ccc;
        &:last-child {
            border-bottom:none;
        }
    }
}
.link-rss {
    background: url(../images/rss-sm.png) no-repeat center center;
    background-size: contain;
    display: block;
    float: right;
    width: 64px;
    height: 32px;
    text-indent: -999px;
    overflow: hidden;
    position: relative;
    margin-top: -2px;
    margin-left: 20px;
}