﻿.buttons {
    text-align: center;
}

.features {
    overflow:hidden;
    .feature {
        width: 25%;
        text-align: left;
        display: block;
        float:left;
        padding: 10px;
        font-size: 90%;
        
        img {
            max-width: 100%;
            height: auto;
            display: block;
            margin: 15px auto 25px;
        }
        
    }

    &.features-columns-1 {
        margin-bottom: 20px;
        .feature {
            width: 100%;
            font-size: $font-size-base;
            margin: 10px 0;
            .attachment-thumb {
                max-width: 100%;
                height: auto;
                display: block;
                margin: 0px auto 25px auto;
            }
            
            
            .feature-description {
                h4 {
                    margin-top: 0;
                    font-size: $font-size-base*1.2;
                    color: $brand-primary;
                }
            }
            
        }
    }
    
    &.features-columns-3 {
        .feature {
            width: 33%;
        }
    }
    &.features-columns-3 {
        .feature:nth-child(3n+1) {
            clear:left;        
        }
    }
       
    &.features-columns-5 {
        .feature {
            width: 20%;
        }
    }
    &.features-columns-5 {
        .feature:nth-child(5n+1) {
            clear:left;
        }        
    }    
}
.buttons-wrap {
    padding: 0 0px 20px;
}
.buttons {
    @include make-row(); 
    .button-columns-2 {
        @include make-sm-column(6);         
    }
    .button-columns-3 {
        @include make-sm-column(4);         
    }
    .button-columns-4 {
        @include make-sm-column(3);         
    }  
    .btn {
        display: block;
        width: 100%;
        margin: 10px 0;
    } 
}

@media(min-width: $screen-sm-min) {
    .features {
        &.features-columns-1 {
            .feature {
                .attachment-thumb {
                    float: left;
                    max-width: 100px;
                    height: auto;
                    margin: 0px 20px 5px 0;
                }
            }
        }
    }
}