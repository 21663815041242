﻿.gallery {
    @include make-row();
    margin: 20px 0;
}
.gallery-item {
    margin-bottom: 20px;
}

.gallery-columns-1 {
    .gallery-item {
        @include make-sm-column(12);
    }
}
.gallery-columns-2 {
    .gallery-item {
        @include make-sm-column(6);
    }
}

.gallery-columns-3 {
    .gallery-item {
        @include make-sm-column(4);
    }
}
.gallery-columns-4 {
    .gallery-item {
        @include make-sm-column(3);
    }
}
.gallery-columns-6 {
    .gallery-item {
        @include make-sm-column(2);
    }
}

@media(max-width: $screen-sm-max) { 
    .gallery-item {
        text-align:center;
    }
    }