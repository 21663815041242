﻿.team-container {
    background: #edebec;
    padding: 65px 0;
}

.team-img {
    border: 1px solid rgb(0,200,255);        
    border-radius: 50%;    
}
.team-name {
    text-transform: none;
    text-align: center;
    font-size: $font-size-base*23/19;
    line-height: 1.5;
    margin: 20px 0 20px;
}
.team-title {
    display: block;
    font-size: $font-size-base*17/19;
}
.team-description {    
    display: none;
    font-size: $font-size-base*18/19;
    color: #fff;
    background:rgba(77,77,77,0.8);
    border-radius: 50%;    
    text-align:center;
    position:absolute;
    left:0;
    right: 0;
    top: 0;
    bottom: 0;
    vertical-align:middle;
    p {
        padding: 25px 20px;
        position:absolute;
        left:0;
        right: 0;
        top: 0;
        bottom: 0;
        vertical-align:middle;
    }   
}

.team-description-toggle {
    border-radius: 50%;
    display: block;
    position: relative;
    overflow: hidden;
}
.team-description-toggle:hover {
    .team-description {
        display: block;
    }
}

.team-linkedin-link {
    display: block;
    background: url(../images/linkedin.png) no-repeat center center;
    height: 40px;
    left: 0;
    right: 0;
    margin-top: -20px;
    position: absolute;
    text-indent: -999px;
    overflow: hidden;
    &:hover {
        opacity: 0.75;
    }
}

@media(min-width: $screen-xs-max){
   
}
@media(min-width: $screen-sm-min){
    .team-superhero:nth-child(3n+1) {
        clear: left;
    }
} 
@media(min-width: $screen-md-min){
    
} 
@media(min-width: $screen-lg-min){
    .team-superhero:nth-child(3n+1) {
        clear: none !important;
    }
    .team-superhero:nth-child(4n+1) {
        clear: left !important;
    }

}
.team-superhero:nth-child(2n+1) {
    clear: none;
}    

@media(max-width: $screen-sm-max){
    .team-container-members {
        max-width: 500px;
        margin: 0 auto;
    }
    .team-superhero {
        width: 50% !important;
    }
    .team-superhero:nth-child(2n+1) {
        clear: left;
    }
    .team-superhero:nth-child(3n+1) {
        clear: none;
    }
    .team-superhero:nth-child(4n+1) {
        clear: none;
    }
}

@media(max-width: $screen-xs-max){
    .team-container-members {
        max-width: 300px;
        margin: 0 auto;
    }
    .team-superhero {
        width: 100% !important;
    }    
}
@media(min-width: 880px){
    
 }