﻿
// banners
.block-banner {
    z-index: 1;
    position: relative;    
    max-height: 275px;
}

.banner-inner {
    position: absolute;
    left:-30px;
    right:0;
    top: 0;
    bottom: 0;
    background-position: cover;    
    /*&.onScreen {
        left:-100px;
        right:-100px;
        top: -50px;
        bottom: -50px;            
        -webkit-transition: all 2s linear 0.3s;
        transition: all 2s linear 0.3s;
    }*/
}
.wrap > .block-banner > .banner-inner  {
    left: 0;
}


.banner-caption-wrap {
    
    position: absolute;
    top: 80px;
    bottom: 0;
    margin: 0 !important;
    
    .banner-caption {
        margin-left: -15px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        text-shadow: 2px 2px 5px #000;
        font-size: $font-size-base * 3.7;
        
        padding-left: $left-column-width;
        
    }
    .banner-caption-logo {
        height: 150px;
        width: 151px;
        margin-left: $left-column-width - 151px;
        content: " ";
        position: absolute;
        margin-top: -50px;
        left: 0px;
        cursor: pointer;
        transform-style: preserve-3d;
        transition: transform 1s;
        
        figure {
            margin: 0;
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
        }
  
        .back {
            background: url(../images/logo-pf2.png) no-repeat 100% 99.2%!important; 
            background-size: 100% auto !important;
            transform: rotateY( 180deg );
        }
        .front {
            background: url(../images/logo-pf2.png) no-repeat 100% 0;            
            background-size: 100% auto;
        }
        
    }
    .banner-caption-logo:hover {
        transform: rotateY( 180deg );
    }
    
    
        
    small {
        font-size: $font-size-base * 2;    
    }
}


@media (min-width: 1500px) {
    
    .fullwidth.block-banner  {
        max-height: 300px;
    }
    
}

@media(max-width: $screen-xs-max) {
    .banner-caption-wrap {
    
        position: absolute;
        top: auto;
        bottom: 50px;
        margin: 0 !important;        
        
        .banner-caption {
            margin-left: 0px;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            text-shadow: 2px 2px 5px #000;
            font-size: $font-size-base * 3;
            
            padding-left: 0;
            
        }
        .banner-caption-logo {
            height: 80px;
            width: 80px;
            background-size: contain;
            content: " ";
            position: absolute;
            margin-top: -100px!important;
            top: 0px;
            left: 50%;
            margin-left: -40px;
        }
        
        
            
        small {
            font-size: $font-size-base * 2;    
        }
    }
}

.carousel .block-banner {
    height: 275px;
}
.carousel-control {
    z-index: 100;
    width: 50px;
    background: transparent !important;
}
.carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
    left: 20px;
}
.carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
    right: 20px;
}