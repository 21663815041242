html, body {margin: 0; padding: 0; overflow-x:hidden;}
body {
    position: relative;
    background: #c9c7c6 url(../images/pf_background.png) repeat center center;
    &#tinymce {
        background: #fff;
    }
}
.wrap {
    padding-top: $navbar-height;
    padding-bottom: $footer-height;
    min-height: 100vh;
    z-index: 0;
    position: relative;
    
    > .container {
        margin: 0 !important;
        padding: 0 15px 0 0 !important;
    }
}
.main {
    font-size: $font-size-base*1.2;
    background: #fff;
    padding-bottom: $footer-height;
    min-height: 60vh;
}
.page-template-template-contact .main {
    padding-bottom: 0;
}


.menu-facebook,
.menu-twitter,
.menu-linkedin {
    
}

h1, h2 {
    font-family: "BigNoodleTitling Regular", "BigNoodleTitling", "bignoodletitlingregular", "Helvetica Neue", Helvetica, Arial, sans-serif;
    small {
        display: block;
        text-transform: none;
        color: inherit;
        line-height: 1.5;
        font-size: 50%;    
    }
}
h1 {
    color: $brand_primary;
}
h3 {
    color: $brand_primary;   
    text-transform: none; 
}
h4 {
    font-weight: normal;
    text-transform: uppercase;
    color: inherit;
    margin-bottom: 0.25em;       
}
h3 {
    line-height:1.25;
}
h5 {
    color: $brand_primary;
}

.map {
    height: 300px;
    margin-top: 20px;
}

.fullwidth {
    padding: 0;
    margin: 0;
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;    
}

.section-spacer {
    padding: 0 0 50px;
    height: 1px;
    position: relative;
    a {
        position: relative;
        top: 0px;

        /* margin-bottom: -50px; */
        display: block;
    }
}
/*
.admin-bar .section-spacer a {
    top: ($navbar-height + $adminbar-height);
}*/


.attachment-sideimage  {
    max-width: 100%;
    height: auto;
}

.main {
    ul {
        list-style: none;
        padding:0;
        margin:5px;
    
        li { 
            padding-left: 1.5em; 
            text-indent: -0.9em;
            padding-top: 2px;
            padding-bottom: 2px;
        }
        
        li:before {
            content: "•";
            font-size: 130%;
            color: $brand-primary;
        }
    }
}

.home-featured {
    h3 {
        color: #414042;
        text-align: center;
        font-size: 40px;
        margin-top: 0;
        font-family: "BigNoodleTitling Regular", "BigNoodleTitling", "bignoodletitlingregular", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    .post-content {
        margin-top: 20px;
    }
}

.lifestyle-row {
    margin: 20px -5px 20px;
    overflow: hidden;
}
.lifestyle-item {
    width: 20%;
    float: left;
    padding: 5px;
}

.page-header {
    margin: 0 0 2em;
    border: none;
}

.page-header h1,
h2.block-content-headline,
h2.block-headline {
    font-size: $font-size-base * 2.5;
    
    background: $brand-primary;
    padding: 40px 40px 10px;
    color: #414042;
    text-transform: uppercase;
    
    margin: 0;
    line-height: 1;

}

.home {
    h2.block-headline {
        font-size: $font-size-base * 2.1;
        padding: 20px 40px 20px;
    }
}

.block-headline-icons {
    @include make-row();
    margin-top: 10px;
}
.block-headline-icon {
     @include make-sm-column(3);
     .block-headline-icon-image {
        width: 45%;
        height: auto;                
        float: left;
        margin-right: 5%;
     }
     h4 {     
        width: 50%;
        float: left;
        color: #fff;
        text-align:left;
        font-size: 25.2px;
     }
}
.home-headline {
    text-align: center;
    position: relative;
    .show-on-hover {
        display:none;
    }
    &:hover {
        .show-on-hover {
            display:block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: $brand-primary;
            text-align:left;
            padding: 20px 40px 20px;
            line-height: 1.5;
            vertical-align: middle;
        }    
    }
}

@media(max-width: $screen-sm-max) { 
    .page-header h1,
    h2.block-content-headline {
        font-size: $font-size-base * 2;
    }
 
    .wrap>.container {
        margin: 0!important;
        padding: 0 0px 0 0!important;
    }
}

@media(max-width: $screen-md-max) { 
    .block-headline-icon {
        h4 {     
            width: 50%;
            font-size: 18px;
        }
    }
    .home-headline {
        &:hover {
            .show-on-hover {    
                padding: 10px 40px;   
                line-height: 1.2;                
            }    
        }
    }
}
@media(max-width: $screen-xs-max) { 
    .block-headline-icon {
         @include make-xs-column(3);
         .block-headline-icon-image {
            width: 100%;
            height: auto;                
            float: none;            
         }
         h4 {     
            width: 100%;
            float: none;
            color: #fff;
            text-align:center;
            font-size: 14px;
         }
    }
    .home-headline {
        &:hover {
            .show-on-hover {    
                padding: 10px 20px;   
                line-height: 1.2;
                font-size: 18px;                
            }    
        }
    }
}