.footer-social-wrap{
		float: left;
		width: $left-column-width;
		text-align: center;
		height: $footer-height;
		span {
			display: inline-block;
			line-height: $footer-height;
		
		}
}
.menu-footer-social {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    height: $footer-height;
    vertical-align: top;
    > li {
        display: inline-block;
        height: $footer-height;
        
        > a {
        transform-style: preserve-3d;
        transition: transform 1s;
            
            display: inline-block;
            overflow: hidden;
            width: $footer-height - 2px;
            height: $footer-height - 2px;
            text-indent: -999px;
            background: url(../images/icons.png) no-repeat 0 0;
            background-size: 100% auto;
            margin: 1px 5px;
            
            &:hover {
                opacity: 0.8;
                transform: rotateY( 180deg );
            }
        }
        &.menu-facebook > a {
            background-position: 0 0;
        }
        &.menu-twitter > a {
            background-position: 0 -47px;
        }
        &.menu-instagram > a {
            background-position: 0 -94px;
        }
        
    }    
}
#scrolldown {
    position: fixed;
    left: 0;
    right: 0;
    bottom: $footer-height + 10px;    
    height: 45px;
    display: none;
    
    .scrolldown-arrow {
        opacity: 0.9;
        width: 100px;
        display: block;
        margin: 0 auto;
        height: 45px;
        background: url(../images/scroll.png) no-repeat center center;
        
        &:hover {
            opacity: 0.75;
        }
    }
    
}

.content-info {
    background: $brand-primary;
    color: rgb(64,64,66);
    height: $footer-height;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 0 0;
    
    font-family: "BigNoodleTitling Regular", "BigNoodleTitling", "bignoodletitlingregular", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: $font-size-base*20/14;
    
    a {
        color: rgb(255,255,255);
        text-decoration: none;
    }
    
    .container {
    		background: rgb(178,178,180);
    		height: $footer-height;
    }
    .menu-footer-nav {
        list-style: none;
        padding: 0;
        line-height: $footer-height;
        margin: 0;
        float:left;
        li {
            display: inline-block;        
            padding: 0;
            margin: 0; 
            a {
                display: inline-block;        
                padding: 0 20px;
                font-size: $font-size-base*22/14;
                text-transform: uppercase;
            }           
        }   
    }
    .copyright {
        float: right;
        padding: 0 20px 0 0;
        line-height: $footer-height;
        
        small{
        		font-size: 80%;
        		padding: 0 0 0 40px;
        }
    }
    .footer-logo-wrap {
    		float: right;
    		margin-right: 15px; 
    		line-height: $footer-height;
    }
    .footer-logo {
    		max-height: 24px;
    }
}

@media(max-width: $screen-xs-max) {
    .content-info .copyright {
        padding-right: 0;
        small {
            font-size: 80%;
            padding: 0 0 0 20px;
        }
    }
    .footer-logo-wrap {
        display: none;
    }
    .footer-social-wrap {
        width: auto;
        > span { 
            display: none; 
        }
    }
    
}